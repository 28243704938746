import React, { useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Button,
	TextContainer,
	Paragraph,
	Ul,
	IconPotentialIssue,
	H4,
} from "@siteimprove/fancylib";
import {
	Knobs,
	Example,
	DocPageMeta,
	InlineMessage,
	ImportExample,
	Code,
	Header,
} from "../../../../../src/docs";
import { HeaderSection, ContentSection } from "../../../../../src/docs/sections";
import { DialogDropzone } from "../dropzone/dropzone";
import { ActionBar } from "../../actions-and-controls/action-bar/action-bar";
import { LabWarning } from "../../../../../src/docs/docs-lab-warning";
import { Modal } from "./modal";

export const Meta: DocPageMeta = {
	category: "Overlay",
	title: "Modal",
	notepad: "https://hackmd.io/0Ab0XGTUSRG2T_ikwmo2VQ",
};

export default (): JSX.Element => (
	<>
		<HeaderSection
			title="Modal"
			subTitle="A modal appears over the original content and interrupts the user's workflow to present critical information or request the user to take action."
		/>
		<ContentSection>
			<TextContainer article>
				<LabWarning />
				<ImportExample lab component={Modal} />
				<Header.H2>Examples</Header.H2>
				<Header.H3>Default</Header.H3>
				<Paragraph>
					In most cases, a <Code>Modal</Code> should always be dismissible and initiated by the
					user. This gives the user the freedom and control to decide when they want to see that
					modal. In certain cases, a system-initiated <Code>Modal</Code> is required to prevent or
					correct critical errors. Use a system-initiated <Code>Modal</Code> sparingly as it
					interrupts the user's current task. The user may be surprised by the unexpected behavior
					of a system-initiated <Code>Modal</Code>.
				</Paragraph>
				<Paragraph>
					The example serves as a base <Code>Modal</Code> used for information-only content and does
					not prompt the user for an action. A <Code>Modal</Code> consists of a header, a close
					button, and body content.
				</Paragraph>
				<Ul
					items={[
						<>Header: includes the modal title (with an optional tooltip) and an optional icon.</>,
						<>
							Body content: provides information and/or controls necessary to complete the modal's
							task.
						</>,
						<>Close button: use the close button to close the modal without submitting any data.</>,
						<>
							Overlay: screen overlay, also referred to as <Code>backdrop</Code>, hides the page's
							content.
						</>,
					]}
				/>
				<Example fn={DefaultExample} />
				<Header.H3>Footer</Header.H3>
				<Paragraph>
					Modals can optionally contain a footer. This section is intended for information and
					actions that relate to the <Code>Modal</Code> as a whole. In most cases, that will be the{" "}
					<GatsbyLink to="/lab/components/Actions and controls/Action Bar">Action Bar</GatsbyLink>{" "}
					component, which required the user to complete or cancel the modal task.
				</Paragraph>
				<Paragraph>
					Add a footer by placing a <Code>Modal.Footer</Code> component inside a <Code>Modal</Code>{" "}
					as its last child.
				</Paragraph>
				<Paragraph>In most cases,</Paragraph>
				<Ul
					items={[
						<>use two buttons to give the user a clear choice.</>,
						<>avoid using more than three buttons.</>,
						<>
							a destructive button can replace a primary button in the footer to confirm actions
							that will cause significant data loss if clicked accidentally.
						</>,
					]}
				/>
				<Example fn={FooterExample} />
				<Header.H3>Sizes</Header.H3>
				<Paragraph>
					Choose a size that works best for the amount of modal content you have. For short
					messages, use a <Code>small</Code> size of a <Code>Modal</Code> to maintain user focus.
					When using <Code>medium</Code> and <Code>fullWidth</Code> <Code>Modal</Code>, use them
					sparingly with consideration for readability.
				</Paragraph>
				<Paragraph>
					Use the <Code>size</Code> property to adjust the width of the <Code>Modal</Code>. These
					are the available sizes and their intended use cases:
				</Paragraph>
				<Ul
					items={[
						<>
							<Code>small</Code> is the default value and is intended to be used for single-column
							layouts.
						</>,
						<>
							<Code>medium</Code> is intended to be used for multiple-column layouts.
						</>,
						<>
							<Code>fullWidth</Code> is intended to present images or present a layout with columns.
						</>,
					]}
				/>
				<Paragraph>
					Here's an example of a <Code>medium</Code> sized <Code>Modal</Code>.
				</Paragraph>
				<Example fn={MediumSizeExample} />
				<H4>Max heights</H4>
				<Paragraph>
					Each <Code>Modal</Code> <Code>size</Code> has a max height to maintain a proper window
					ratio. Consider sizing up the modal if vertical scrolling is too much of a problem due to
					a max height. See the <a href="#guidelines">scrolling behavior</a> below. It may be
					necessary to use a full page instead of a <Code>fullWidth</Code> modal if the height of
					the <Code>fullWidth</Code> modal is still not sufficient. Especially if the content
					contains a lot of data and it takes a while to load.
				</Paragraph>
				<Paragraph>
					Here's an example of a <Code>fullWidth</Code> <Code>Modal</Code>.
				</Paragraph>
				<Example fn={FullWidthSizeExample} />
				<Header.H3>Padding</Header.H3>
				<Paragraph>
					The <Code>Modal.Content</Code> component provides the same <Code>padding</Code> options as
					the <GatsbyLink to="/lib/components/Structure/Content">Content</GatsbyLink> component:
				</Paragraph>
				<Ul
					items={[
						<>
							<Code>large</Code>
						</>,

						<>
							<Code>medium</Code> (default value)
						</>,

						<>
							<Code>small</Code>
						</>,

						<>
							<Code>none</Code>
						</>,

						<>
							<Code>compact</Code>
						</>,
					]}
				/>
				<Paragraph>
					The example shows a <Code>Modal.Content</Code> with the <Code>padding</Code> property set
					to <Code>large</Code>.
				</Paragraph>
				<Example fn={PaddingExample} />
				<Header.H3>Tooltip in header</Header.H3>
				<Paragraph>
					Adding a tooltip to the header is not recommended. The header should be clear and inform
					the user about the purpose of the modal.
				</Paragraph>
				<Paragraph>A header should only contain a tooltip if we need to:</Paragraph>
				<Ul
					items={[
						<>introduce an unfamiliar term;</>,
						<>reassure the user to perform the required actions.</>,
					]}
				/>
				<Paragraph>
					A tooltip in the header should not prompt the user to take any additional action.
				</Paragraph>
				<Paragraph>
					To add a tooltip to the header you must use the <Code>headerTitleTooltipText</Code> prop
					to ensure proper accessibility. Do not add the{" "}
					<GatsbyLink to="/lib/components/Overlay/Tooltip">Tooltip component</GatsbyLink> to the{" "}
					<Code>headerTitle</Code> prop.
				</Paragraph>
				<Paragraph>
					If you need to change the placement of the tooltip you can use the{" "}
					<Code>headerTitleTooltipPlacement</Code> prop.
				</Paragraph>
				<Example fn={TooltipHeaderExample} />
				<Header.H3>Icon in header</Header.H3>
				<Paragraph>
					Use a header with an information icon to remind the user of the importance of taking a
					specific action. Use a consistent color and purpose for each type of information icon, as
					described in the <GatsbyLink to="/lab/components/Feedback/Message">Message</GatsbyLink>{" "}
					component.
				</Paragraph>
				<Example fn={IconHeaderExample} />
				<Header.H3>No close button in header</Header.H3>
				<Paragraph>
					Be careful when using this variant. If the close button is not provided, the user is
					forced to understand the content and make a decision about which button to choose to
					continue the workflow.
				</Paragraph>
				<Paragraph> This variant is recommended to use only when</Paragraph>
				<Ul
					items={[
						<>the system requires the user to acknowledge the presented information.</>,

						<>
							the user’s actions may destroy data or cause significant changes. This allows the user
							to confirm the actions.
						</>,
					]}
				/>
				<Example fn={HideCloseExample} />
				<Header.H3>Dropzone</Header.H3>
				<Paragraph>
					<Code>DialogDropzone</Code> is not a feature for end users. It is a structural component
					that allows developers to control where the <Code>Dialog</Code> component will appear in
					the Document Object Model (DOM).
				</Paragraph>
				<Paragraph>
					By default, modals are appended to the DOM in the bottom of the <Code>body</Code> element.
					The <Code>DialogDropzone</Code> component lets you control where in the DOM the{" "}
					<Code>Modal</Code> is rendered, allowing it to inherit styles, if you don't use a global
					stylesheet.
				</Paragraph>
				<Example fn={DropzoneExample} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={Modal}
					initialProps={({ setState }) => ({
						shown: false,
						headerTitle: "Header text",
						onClose: () => setState({ shown: false }),
						children: (
							<Modal.Content>
								<Paragraph>
									Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
									obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
									nesciunt at, placeat sint vel quas quae aliquid.
								</Paragraph>
							</Modal.Content>
						),
					})}
				/>
				<Header.H2>Guidelines</Header.H2>
				<Header.H3>Best practices</Header.H3>
				<InlineMessage variant="best-practices">
					<Header.H4>General</Header.H4>
					<Paragraph>
						Use <Code>Modal</Code> sparingly to limit the interruption to the user.
					</Paragraph>
					<Paragraph>
						Use <Code>Modal</Code> when
					</Paragraph>
					<Ul
						items={[
							<>important warnings are displayed to avoid or correct critical errors.</>,
							<>
								inviting the user to enter the information needed to continue the process.
								Preferably, the requested input will help the user to reduce effort or streamline
								the current task.
							</>,
						]}
					/>
					<Header.H4>Actions</Header.H4>
					<Paragraph>
						<Code>Modal</Code> is typically used for the following actions:
					</Paragraph>
					<Ul
						items={[
							<>
								<H4>Customize data or displayed content</H4>
								<Code>Modal</Code> can be used to require the user to respond immediately or to
								perform targeted tasks. This type of action should be immediate. If a longer load
								time is required, you can use the{" "}
								<GatsbyLink to="/lib/components/Feedback/spinner">Spinner</GatsbyLink> to inform the
								user that their action is being processed. The primary action button should have a
								load status and disable other buttons while the load is in progress. See the{" "}
								<GatsbyLink to="/lab/components/Actions and controls/Action Bar">
									Action Bar
								</GatsbyLink>{" "}
								guideline. An example of the modal can be found in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FAccessibility%2F446001%2FNextGen%2FOverview%3FpageSegments%3D%26conformance%3D%26siteGoal%3D%26lang%3Den-US">
									Accessibility Overview {">"} Set site target.
								</a>
							</>,

							<>
								<H4>Inform the user about critical information</H4>
								Use a modal to present information that the user needs to pay attention to in their
								current workflow. An example of the modal can be found when first entering{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FSettings%2FAnalyticsDataRetention%2FIndex%3Flang%3Den-US">
									{"Analytics > Analytics settings > Data Retention"}
								</a>
								.
							</>,

							<>
								<H4>Confirm a user decision</H4>
								This action should only be used when the user takes uncommon destructive and
								irreversible actions. Inform the user about the consequence of their actions,
								suggest alternatives, and remind them if the action cannot be reversed. If the
								destructive action can be undone, the user does not need to confirm the action. An
								example of the modal can be found in{" "}
								<a href="https://my2.siteimprove.com/Auth/Direct?personId=128151299&accountId=30156&back=%2FPerformance%2Fprofiles%3Flang%3Den-US">
									{"Performance > Performance profiles"}
								</a>
								.
							</>,
						]}
					/>
					<Header.H4>Scrolling behavior</Header.H4>
					<Paragraph>
						A <Code>Modal</Code> with a scrolling view makes it more difficult for the user to read
						and complete the task. Therefore, scrolling behavior is discouraged in most cases. Be
						sure to minimize the potential for scrolling by keeping the modal's header short and
						containing only the necessary content.
					</Paragraph>
					<Ul
						items={[
							<>
								The user should never scroll horizontally to see the content of the{" "}
								<Code>Modal</Code>.
							</>,

							<>
								Avoid vertical scrolling when possible. If you must use the scroll bar, make sure
								there is a clear visual connection to the content and a close button or a Cancel
								button so the user can exit the <Code>Modal</Code> at any time.
							</>,

							<>
								The header and footer of the <Code>Modal</Code> remain fixed when you display a
								scrollable list of options.
							</>,

							<>
								Avoid double scrolling. Clearly define the scrolling area. The user should not
								scroll with elements outside the <Code>Modal</Code>, such as the background.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Do not use when</Header.H3>
				<InlineMessage variant="do-not-use-when">
					<Ul
						items={[
							<>
								the user flow is complex and involves multiple steps or needs to be scrollable.
								Instead, create the flow on a separate page.
							</>,
							<>
								presenting complex or large amounts of data that require additional sources of
								information that are not included in the modal.
							</>,
							<>
								there is no connection between the content and the current workflow. For example,
								ads and email signups are important to generate business leads, but they are not
								crucial to the user. This only makes users annoyed and increases the interaction
								cost of closing a modal, as it does not serve the user's goal.
							</>,
							<>
								displaying low-priority information. For example, displaying an error, warning, or
								success status. In this case, use{" "}
								<GatsbyLink to="/lab/components/Feedback/Toast">Toast</GatsbyLink> instead.
							</>,
							<>
								displaying prominent or medium-priority information. In this case, use{" "}
								<GatsbyLink to="/lab/components/Feedback/Message">Message</GatsbyLink> instead.
							</>,
						]}
					/>
				</InlineMessage>
				<Header.H3>Accessibility</Header.H3>
				<InlineMessage variant="accessibility">
					<H4>For designers</H4>
					<Ul
						items={[
							<>
								<Code>Modal</Code> should never be nested. For example, one modal cannot be
								triggered by another modal.
							</>,
							<>
								the user should not be able to interact with any element on the page outside of the{" "}
								<Code>Modal</Code> as long it is open.
							</>,
							<>
								<Code>Modal</Code> must contain a <Code>{"<h1>"}</Code> header.
							</>,
							<>
								It would be best if both the close button and the Cancel button were present. This
								would help both users who use only the keyboard and screen readers who navigate
								slowly using assistive technologies.
							</>,
							<>
								Ensure when a <Code>Modal</Code> closes, the keyboard focus is returned to the
								button that triggered the <Code>Modal</Code>, if possible.
							</>,
						]}
					/>
					<H4>For developers</H4>
					<Ul
						items={[
							<>
								Make sure that a <Code>Modal</Code> trigger button has the{" "}
								<Code>aria-haspopup="dialog"</Code> attribute. This way, screen reader users know
								what to expect when they click the button.
							</>,
							<>
								Allow the user to close a <Code>Modal</Code> with the <Code>Esc</Code> key at any
								time.
							</>,
						]}
					/>
				</InlineMessage>
				<Paragraph>
					Explore detailed guidelines for this component:{" "}
					<a href="https://siteimprove-wgs.atlassian.net/wiki/x/4wQNeQ">
						Accessibility Specifications
					</a>
				</Paragraph>
				<Header.H3>Writing</Header.H3>
				<InlineMessage variant="writing">
					<H4>Header</H4>
					<Ul
						items={[
							<>
								Write a short question or statement, using a verb+noun combination that clearly
								describes the modal purpose. For example: 'Add keywords to track your ranking'.
							</>,
							<>Use sentence case.</>,
							<>Avoid unnecessary words such as the, an, or a.</>,
							<>
								Avoid apologies ('Sorry for the interruption'), alarm ('Warning!'), or ambiguity
								('Are you sure?').
							</>,
						]}
					/>
					<H4>Body content</H4>
					<Ul
						items={[
							<>Only provide the user with content relevant to the task or flow.</>,
							<>Present the most critical information first. </>,
							<>
								Be direct and use a neutral, approachable tone. See{" "}
								<a href="https://fancy.siteimprove.com/Writing/tone-of-voice#standard-ux-microcopy">
									standard UX microcopy
								</a>{" "}
								to better understand users' emotions.
							</>,
						]}
					/>
					<H4>Footer</H4>
					<Ul
						items={[
							<>
								Button text should start with a verb to encourage action. For example, a modal
								titled "Add a widget to the dashboard" has a button titled "Add widget". Follow the
								writing guideline for{" "}
								<GatsbyLink to="/lab/components/Actions and controls/Button">Button</GatsbyLink>.
							</>,
							<>
								Button text should enable the user to predict what will happen when they click the
								button.
							</>,
						]}
					/>
				</InlineMessage>
			</TextContainer>
		</ContentSection>
	</>
);

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const DefaultExample = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowModal(true)}>
				Trigger modal
			</Button>
			<Modal shown={showModal} headerTitle="Default modal" onClose={() => setShowModal(false)}>
				<Modal.Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Modal.Content>
			</Modal>
		</>
	);
};

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const FooterExample = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowModal(true)}>
				Trigger modal
			</Button>
			<Modal shown={showModal} headerTitle="Modal with footer" onClose={() => setShowModal(false)}>
				<Modal.Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Modal.Content>
				<Modal.Footer>
					<ActionBar
						primary={{ children: "Confirm", onClick: () => setShowModal(false) }}
						cancel={{ children: "Cancel", onClick: () => setShowModal(false) }}
					/>
				</Modal.Footer>
			</Modal>
		</>
	);
};

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const MediumSizeExample = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowModal(true)}>
				Trigger modal
			</Button>

			<Modal
				shown={showModal}
				size="medium"
				headerTitle="Medium sized modal"
				onClose={() => setShowModal(false)}
			>
				<Modal.Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Modal.Content>
			</Modal>
		</>
	);
};

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const FullWidthSizeExample = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowModal(true)}>
				Trigger modal
			</Button>

			<Modal
				shown={showModal}
				size="fullWidth"
				headerTitle="Full width modal"
				onClose={() => setShowModal(false)}
			>
				<Modal.Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Modal.Content>
			</Modal>
		</>
	);
};

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const PaddingExample = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowModal(true)}>
				Trigger modal
			</Button>

			<Modal
				shown={showModal}
				headerTitle="Modal with large padding"
				onClose={() => setShowModal(false)}
			>
				<Modal.Content padding="large">
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Modal.Content>
			</Modal>
		</>
	);
};

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const TooltipHeaderExample = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowModal(true)}>
				Trigger modal
			</Button>
			<Modal
				shown={showModal}
				headerTitle="Header title with tooltip"
				headerTitleTooltipText="Lorem ipsum dolor sit amet consectetur adipisicing elit."
				headerTitleTooltipPlacement="bottom"
				onClose={() => setShowModal(false)}
			>
				<Modal.Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Modal.Content>
			</Modal>
		</>
	);
};

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const IconHeaderExample = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowModal(true)}>
				Trigger modal
			</Button>
			<Modal
				headerIcon={<IconPotentialIssue />}
				headerTitle="Header title with icon"
				shown={showModal}
				onClose={() => setShowModal(false)}
			>
				<Modal.Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Modal.Content>
			</Modal>
		</>
	);
};

/**
 * @click button
 * @visualDiffSelector dialog
 * */
const HideCloseExample = () => {
	const [showModal, setShowModal] = useState(false);

	return (
		<>
			<Button aria-haspopup="dialog" onClick={() => setShowModal(true)}>
				Trigger modal
			</Button>
			<Modal
				headerTitle="Header without close button"
				shown={showModal}
				onClose={() => setShowModal(false)}
				hideClose
			>
				<Modal.Content>
					<Paragraph>
						Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro nemo repellendus
						obcaecati praesentium illo, voluptate fugiat quisquam vitae nostrum modi earum eos
						nesciunt at, placeat sint vel quas quae aliquid.
					</Paragraph>
				</Modal.Content>
				<Modal.Footer>
					<ActionBar
						primary={{ children: "Confirm", onClick: () => setShowModal(false) }}
						cancel={{ children: "Cancel", onClick: () => setShowModal(false) }}
					/>
				</Modal.Footer>
			</Modal>
		</>
	);
};

/** @ignore [visual] */
const DropzoneExample = () => <DialogDropzone />;
